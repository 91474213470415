/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
// import Image from './Image'

import './Content.css'

// const MyImage = ({ nodeKey, src, title, alt }) => {
//   const decodedSrc = decodeURI(src)
//   return (
//     <Image
//       className="Content--Image markdown-preview"
//       resolutions="medium"
//       lazy={false}
//       src={decodedSrc}
//       title={title}
//       alt={alt}
//     />
//   )
// }

const Content = ({ body, className = '', extraComponent = null }) => {
  // accepts either html or markdown
  return (
    <div className={`Content ${className}`} style={{ wordBreak: 'break-word' }}>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      {extraComponent}
    </div>
  )
}

Content.propTypes = {
  body: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Content
