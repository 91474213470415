/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
// import { Modal } from 'react-responsive-modal'
import PureModal from 'react-pure-modal'

import PageHeader from '../components/PageHeader'

// import PostSection from '../components/PostSection'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
const BookingPageTemplate = ({ data }) => {
  // const myRef = useRef(null)
  const {
    title,
    subTitle,
    featuredImage,
    body,
    termsAndConditions,
    termsAndConditionsHeading
  } = data.directus.bookingPage
  const [open, setOpen] = useState(null)
  const [modalContent, setModalContent] = useState('terms')

  const onOpenModal = (url) => setOpen(url)
  const onCloseModal = () => {
    setOpen(null)
    setTimeout(() => {
      setModalContent('terms')
    }, 500)
  }

  const openFrame = () => {
    setModalContent('frame')
  }

  // useEffect(() => {
  //   if (open === true) {
  //     setTimeout(() => {
  //       const d = myRef.current
  //       d.scrollTop = 0
  //     }, 100)
  //   }
  // }, [open])

  return (
    <main className="Home">
      <PageHeader
        large
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section">
        <div className="container">
          <Content
            body={body}
            extraComponent={
              <div style={{ display: 'inline-grid' }}>
                <button
                  className="Button"
                  onClick={() => {
                    onOpenModal(
                      'https://squareup.com/appointments/book/jhfauekaf2yzj3/LK2HN6REAY4GE/start'
                    )
                  }}
                >
                  Book Field 1
                </button>
                <button
                  className="Button"
                  style={{ marginTop: 8 }}
                  onClick={() => {
                    onOpenModal(
                      'https://squareup.com/appointments/book/c4sspu78eg89rf/LV8EBB9GVBW35/start'
                    )
                  }}
                >
                  Book Field 2
                </button>
              </div>
            }
          />
        </div>
      </section>
      <PureModal width={700} height={800} isOpen={open} onClose={onCloseModal}>
        <div>
          {modalContent === 'terms' && (
            <div className="customModalC">
              <Content body={termsAndConditionsHeading} />
              <Content
                body={termsAndConditions}
                extraComponent={
                  <div style={{ marginTop: 32, textAlign: 'center' }}>
                    <button className="Button" onClick={openFrame}>
                      ACCEPT
                    </button>
                  </div>
                }
              />
            </div>
          )}
          {modalContent !== 'terms' && (
            <div style={{ paddingTop: '40px' }}>
              <object
                type="text/html"
                data={open}
                width="800px"
                height="600px"
              />
              {/* <iframe
                height="100%"
                width="100%"
                src="https://squareup.com/appointments/buyer/widget/rv58semli2dup2/LK2HN6REAY4GE"
                frameBorder="0"
                allowFullScreen
              /> */}
            </div>
          )}
        </div>
      </PureModal>
    </main>
  )
}

// Export Default HomePage for front-end
const BookingPage = ({ data }) => {
  return (
    <Layout meta={data.directus.bookingPage.SEO || false}>
      <BookingPageTemplate data={data} />
    </Layout>
  )
}

export default BookingPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query BookingPage {
    directus {
      bookingPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        termsAndConditions
        termsAndConditionsHeading
        SEO {
          image {
            id
          }
          title
          description
        }
      }
    }
  }
`
