/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Observer from './Observer'

import './Image.css'

const Image = (props) => {
  const ref = useRef(null)

  const fullImage = false

  const imageSizes = [
    '320',
    '450',
    '640',
    '750',
    '800',
    '900',
    '1000',
    '1200',
    '1500',
    '1600',
    '2000'
  ] // image sizes used for image source sets

  const [isIntersecting, setIsIntersecting] = useState(false)

  const handleIntersection = (e) => {
    if (e.isIntersecting) {
      setIsIntersecting(true)
    }
  }

  const getResolutionString = (res) => {
    /* add resolutions options for inline images */
    if (res === 'small') {
      res = '800'
    } else if (res === 'medium') {
      res = '1000'
    } else if (res === 'large') {
      res = '2000'
    }
    return res
  }

  let {
    background,
    backgroundSize = 'cover',
    resolutions = '1000',
    className = '',
    src,
    secSet = '',
    fullSrc,
    smallSrc,
    title = '',
    alt = '',
    lazy = true
  } = props

  // const isUploadcare = this.checkIsUploadcare(src),
  //   fullImage = !isUploadcare || !lazy

  /* create source set for images */
  secSet = imageSizes.map((size) => {
    // example.com/assets/1ac73658-8b62-4dea-b6da-529fbc9d01a4?fit=cover&width=200&height=200&quality=80
    //   const img = `https://jokerswmc.clockworkbee.co.uk/assets/${imageId}?&width=1024&fit=contain`;

    return `https://wltdoc.clockworkbee.co.uk/assets/${src.id}?width=${size}&quality=25&format=jpg`
  })
  fullSrc = `https://wltdoc.clockworkbee.co.uk/assets/${
    src.id
  }?width=${getResolutionString(resolutions)}&format=jpg`

  // fullSrc = `${src}${`-/progressive/yes/-/format/auto/-/resize/${getResolutionString(
  //   resolutions
  // )}/`}
  // }`

  smallSrc = `https://wltdoc.clockworkbee.co.uk/assets/${src.id}?width=10`

  let style = {}
  if (background && lazy) {
    style = {
      backgroundImage: `url(${isIntersecting ? fullSrc : smallSrc})`,
      backgroundSize
    }
  } else {
    style = {
      backgroundImage: `url(${fullSrc})`,
      backgroundSize
    }
  }

  return (
    <>
      {lazy && (
        <Observer onChange={handleIntersection}>
          <div
            className="BackgroundImage"
            ref={ref}
            style={{
              backgroundImage: `url(${smallSrc})`,
              backgroundSize: 'cover'
            }}
          >
            {!background && (
              <img
                className={`LazyImage ${
                  className + isIntersecting ? ' faded' : ''
                }`}
                src={isIntersecting ? fullSrc : ''}
                srcSet={isIntersecting ? secSet : ''}
                sizes="100vw"
                title={title}
                alt={alt}
              />
            )}
            {background && (
              <div
                className={`${lazy} LazyImage BackgroundImage absolute ${
                  className + isIntersecting ? ' faded' : ''
                }`}
                style={style}
              />
            )}
          </div>
        </Observer>
      )}
      {fullImage && (
        <>
          {background && (
            <div
              className={`BackgroundImage absolute ${className}`}
              style={style}
            />
          )}
          {!background && (
            <img
              className={`${className}`}
              src={fullSrc}
              srcSet={secSet}
              sizes="100vw"
              title={title}
              alt={alt}
            />
          )}
        </>
      )}
    </>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired
}

export default Image
